
import React, { Component } from 'react';
import one from '../../Assets/Cabin/1.jpg';
import two from '../../Assets/Cabin/2.jpg';
import three from '../../Assets/Cabin/3.jpg';
import four from '../../Assets/Cabin/4.jpg';
import five from '../../Assets/Cabin/5.jpg';
import six from '../../Assets/Cabin/6.jpg';
import Fade from 'react-reveal/Fade';
class Cabin extends Component {
    state = {  } 
    render() { 
        return (
            <div id='project-gallery'>
                <div className='top-title'>
                    <p className='title'>Cabin Remodel</p>
                    <p></p>
                </div>
                <div className='project-card'>
                    <div>
                        <Fade>
                        <img src={one}/>
                        <img src={two}/>
                        <img src={three}/>
                        <img src={four}/>
                        <img src={five}/>
                        <img src={six}/>
                        </Fade>
                      </div>

                </div>
                <div className='bottom-title'>
                    <p className='title'>Cabin Remodel</p>
                    <p>  </p>
                </div>

            </div>

        );
    }
}
 
export default Cabin;